import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Course} from '../model/Course'
import { Observable, catchError, throwError } from 'rxjs';
import { Doc } from '../model/Doc';
import { Ingested } from '../model/Ingested';
import { Answer } from '../model/Answer';
import { Exam } from '../model/Exam';
import { Template } from '../model/Template';
import { environment } from '../environments/environment';
import { SSDDetails } from '../model/SSDDetails';
import { KeyValue } from '../model/KeyValue';
import { Admission } from '../model/Admission';

@Injectable({
  providedIn: 'root'
})

export class MainService {
    base_url = environment.BASE_WS_URL;

    constructor(private http: HttpClient) {}

    private handleError(error: HttpErrorResponse) {
      console.log(error);
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
      }
      // Return an observable with a user-facing error message.
      return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    getDocs(): Observable<Doc[]> {
      return this.http.get<Doc[]>(this.base_url + '/doc');
    }

    getDigestDoc(docId:String): Observable<Ingested[]> {
      return this.http.get<Ingested[]>(this.base_url + '/ingest/' + docId);
    }

    sendIngestedDoc(docId:String, ingestedDocs:any): Observable<Ingested[]> {
      return this.http.post<Ingested[]>(this.base_url + '/ingest/' + docId, ingestedDocs);
    }

    ask(course:String, question: string, template: string): Observable<Answer> {
      return this.http.post<Answer>(this.base_url + '/ask/' + course, {'question': question, 'template': template});
    }

    askWithPrompt(prompt:String): Observable<Answer> {
      return this.http.post<Answer>(this.base_url + '/prompt', {'prompt' : prompt});
    }

    askExams(course:String, degree_type:string, vote:number|null, exams: Exam[], language:string, template: string): Observable<Answer> {
      return this.http.post<Answer>(this.base_url + '/ask/exams/' + course,
        {'degree_type': degree_type, 'vote': vote, 'exams': exams, 'template': template, 'language': language});
    }

    getTemplate(ask_type:String): Observable<Template> {
      return this.http.get<Template>(this.base_url + '/template/' + ask_type);
    }

    saveTemplate(ask_type:String, template_body: string): Observable<void> {
      return this.http.post<void>(this.base_url + '/template/' + ask_type, {'template_body' : template_body});
    }

    getSSD(): Observable<KeyValue[]> {
      return this.http.get<KeyValue[]>(this.base_url + '/ssd');
    }

    getSSDWithDetails(): Observable<SSDDetails> {
      return this.http.get<SSDDetails>(this.base_url + '/ssd_with_details');
    }

    digestExamsDocument(file:File): Observable<any>{
      const formData = new FormData();
      formData.append('file', file);
      return this.http.post<any>(this.base_url + '/exams/doc/digest', formData);
    }

    saveAdmission(admission:Admission): Observable<Admission> {
      return this.http.post<Admission>(this.base_url + '/admission', admission);
    }

    getAdmission(id_admission:string): Observable<Admission> {
      return this.http.get<Admission>(this.base_url + '/admission/' + id_admission);
    }

    getAdmissions(): Observable<Admission[]> {
      return this.http.get<Admission[]>(this.base_url + '/admission');
    }

}
