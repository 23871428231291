import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../service/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  
  constructor(private _authService: AuthService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authService.currentUserValue;
    const isLoggedIn = currentUser && this._authService.validToken;
    if (isLoggedIn){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._authService.token}`
        }
      });      
    } else {
      console.log('intercept relogin')
    }
    return next.handle(request);    
  }
}