<div class="container pb-2 mt-4">
	<h2>
		<i class="bi bi-chat-right-text-fill"></i> Verifica di Ammissione {{admission.short_id ? admission.short_id : admission.id}} effettuato il {{admission.inserted_at}}
	</h2>
	<div class="card mt-4">
		<div class="card-body">	
			<div class="form-group mb-4 mt-4">
				<label class="mb-2 me-2 fw-bold" >Nome</label>
				<input
					style="width: 40%;"
					class="form-control"
					type="text"
					[(ngModel)]="admission.name"
					[readonly]="true"
				/>
			</div>
			<div class="form-group mb-4">
				<label class="mb-2 fw-bold">Laurea</label>
				<div class="d-flex">
					<div class="mb-2 me-2 degree-type">
						Tipo
						<ng-select
							[searchable]="true"
							[items]="degreeTypes"
							bindLabel="value"
							bindValue="key"
							placeholder="Seleziona una laurea"
							class="w-100"
							[(ngModel)]="admissionDegree_type"
							[readonly]="true"
						>
						</ng-select>
					</div>
					<div class="degree-vote">
						<span class="text-nowrap">Voto (in 110)</span>
						<ng-select
							[items]="degree_votes"
							bindLabel="value"
							bindValue="key"
							[(ngModel)]="admissionVote"
							[readonly]="true"
						>
						</ng-select>
						<!-- <input
							class="form-control"
							type="number"
							[(ngModel)]="exam_vote"
							min="60"
							max="110"
						/> -->
					</div>
				</div>
			</div>

			<div class="form-group mb-4" *ngIf="!isChat()">
				<label class="mb-2 fw-bold"
					>Esami -
					</label
				>
				<table class="table">
					<thead>
						<tr>
							<th>SSD</th>
							<th>Descrizione</th>
							<th>CFU</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let exam of admissionExams; let i = index">
							<td style="width: 15%;" class="pe-2">
								<ng-select
									[searchable]="true"
									[clearable]="true"
									[items]="ssds"
									bindLabel="key"
									bindValue="key"
									[(ngModel)]="exam.ssd"
									[readonly]="true"
								>
								</ng-select>
							</td>
							<td style="width: 55%;">
								<input
									class="form-control"
									type="text"
									[(ngModel)]="exam.description"
									[readonly]="true"
								/>
							</td>
							<td style="width: 10%;" class="pe-2">
								<input
									class="form-control"
									type="number"
									[(ngModel)]="exam.cfu"
									[readonly]="true"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="form-group mb-4 language-vote" *ngIf="!isChat()">
				<label class="mb-2 fw-bold">Competenze linguistiche</label>
				<ng-select
					[searchable]="true"
					[items]="languageLevels"
					bindLabel="value"
					bindValue="key"
					placeholder="Seleziona una lingua"
					[(ngModel)]="admissionLanguage"
					[readonly]="true"
				>
				</ng-select>
			</div>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<h3>
				<i class="bi bi-chat-left-text me-2"></i>
				<span>Esito</span>
			</h3>
			<div class="d-flex mt-4">
				<div class="w-100">
					<div
						class="border border-danger p-4 rounded"
						*ngIf="admission.outcome=='NO'"
					>
						<div class="d-flex">
							<div class="me-4">
								<i class="bi bi-exclamation-octagon-fill text-danger h1"></i>
							</div>
							<div>
								<h4>Ammissione al corso non possibile</h4>									
								<h5>I seguenti requisiti non sono soddisfatti:</h5>
								<ul class="reasons mt-2 mb-0">
									<li *ngFor="let reason of admission.response.error">{{ reason }}</li>
								</ul>
								<br>
								<span>{{admission.response.reason}}</span>									
							</div>
						</div>
					</div>

					<div
						class="border border-success p-4 rounded"
						*ngIf="admission.outcome=='YES'"
					>
						<div class="d-flex align-items-center">
							<div class="me-4">
								<i class="bi bi-check-circle-fill text-success h1"></i>
							</div>
							<div>
								<h4 class="mb-0">Ammissione al corso possibile</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
