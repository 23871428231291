import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';
import { Course } from '../../model/Course';
import { Exam } from '../../model/Exam';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValue } from '../../model/KeyValue';
import { Admission } from '../../model/Admission';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
	admission:Admission =  new Admission()
	admissionLanguage!: string;
	admissionDegree_type!: string;
	admissionVote!: string;
	admissionExams:Array<Exam> = []
	course!: Array<KeyValue>;
	c!: string;
	chat!: boolean;
	question!: string;
	answer!: string;
	asking: boolean = false;
	showTemplate: boolean = false;
	showTrace: boolean = false;
	template: string = '';
	trace!: string;
	reasons!: Array<String>;
	reason!: string
	prompt!: string;
	failurePattern: Array<string> = ['RISPOSTA FINALE (SI/NO): No', 'No '];
	successPattern: Array<string> = ['RISPOSTA FINALE (SI/NO): Si', 'Si '];
	ssds: Array<KeyValue> = [];
	languageLevels: Array<KeyValue> = [];
	savingMessage: string|null = null
	
	degreeTypes: Array<KeyValue> = [];
  	degree_votes: Array<KeyValue> = [];
	selectedFile: File | null = null;
	documentProcessing = false;
	constructor(private _mainService: MainService, private router: Router, private activatedRoute: ActivatedRoute) {
		
	}

	/**
	 * On init
	 */
	ngOnInit() {
		this.getSSD();			
	}

	getAdmission(){
		const idAdmission = this.activatedRoute.snapshot.params['id_admission'];
		if(idAdmission){
			this._mainService.getAdmission(idAdmission)
			.subscribe((admission) => {
				if (admission) {
					this.admission = admission;
					this.admissionLanguage = this.admission.request['language']
					this.admissionDegree_type = this.admission.request['degree_type']
					this.admissionVote = this.admission.request['vote']
					this.admissionExams = this.admission.request['exams']
				}
			})
		}
	}

	isChat() {
		return this.router.url.includes('/chat');
	}

	isAdmission() {
		return this.router.url.includes('/admission');
	}

	getAskType() {
		return this.isChat() ? 'chat' : 'admission';
	}

	changeAskType() {
		// this.getTemplate();
		// this.answer = '';
	}

	/*getTemplate(): void {
		this.template = '';
		this._mainService.getTemplate(this.getAskType())
		.subscribe((te) => {
			if (te) {
				this.template = te.body;
			}
		})
	}*/

	getSSD() {
		this._mainService.getSSDWithDetails()
		.subscribe(
			{
				next: (result) => {
				if (result) {
					this.ssds = result.ssd;
					this.degreeTypes = result.degree;
					this.languageLevels = result.language;
					this.degree_votes = result.degree_vote;
					this.course = result.course;
				}
				},
				error: (e) => {
				console.log(e);
				},
				complete: () => {
					console.info('complete')
					this.getAdmission()
				}
			}
		);
	}

  prePopulateDescription(ssd: any, index: number){
    //this.admission.exams[index].description = ssd.value;
  }

	/*ask(): void {
		this.asking = true;
		this.answer = '';
		this._mainService
			.saveTemplate(this.getAskType(), this.template)
			.subscribe((result) => {});
		if (this.isChat()) {
			this._mainService
				.ask(this.c, this.question, this.template)
				.subscribe((answer) => {
					this.asking = false;
					this.answer = answer.answer;
					this.trace = answer.trace;
          			this.reasons = answer.error;
				});
		} else {
			this._mainService
				.askExams(
					this.c,
					this.admission.degree_type,
					this.admission.exam_vote,
					this.admission.exams,
          			this.admission.languageLevel,
					this.template
				)
				.subscribe(
          {
            next: (answer) => {
              this.asking = false;
              this.answer = answer.answer;
              this.trace = answer.trace;
              this.reasons = answer.error;
			  this.reason = answer.reason;
            },
            error: (e) => {
              this.asking = false;
              this.answer = e.status + ', ' + e.statusText;
            }
          }


          );
		}
	}*/

	askWithPrompt() {
		this.asking = true;
		this.answer = '';
		this._mainService.askWithPrompt(this.prompt).subscribe((answer) => {
			this.asking = false;
			this.answer = answer.answer;
			this.trace = answer.trace;
			this.reason = answer.reason;
		});
	}

	capitalizedCourse(course: any) {
		var splitStr = course.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] =
				splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		// Directly return the joined string
		return splitStr.join(' ');
	}

	toggleTemplate() {
		this.showTemplate = !this.showTemplate;
	}

	toggleTrace() {
		this.showTrace = !this.showTrace;
	}

	addExam(): void {
		const exam = new Exam();
		//this.admission.exams.push(exam);
	}

	removeExam(i: number): void {
		/*const newExams: Exam[] = [];
		for (var j = 0; j < this.admission.exams.length; j++) {
			if (i != j) {
				newExams.push(this.admission.exams[j]);
			}
		}*/
		//this.admission.exams = newExams;
	}

	isFailure() {
		// let isFailure = false;
		// this.failurePattern.forEach((stringToCheck) => {
		// 	if (this.answer.toLowerCase().startsWith(stringToCheck.toLowerCase())) {
		// 		isFailure = true;
		// 	}
		// });
		return this.answer === "NO";
	}

	isSuccess() {
		// let isSuccess = false;
		// this.successPattern.forEach((stringToCheck) => {
		// 	if (this.answer.toLowerCase().startsWith(stringToCheck.toLowerCase())) {
		// 		isSuccess = true;
		// 	}
		// });
		return this.answer === "YES";
	}

	onFileChange(event: Event) {
		const files = (event.target as HTMLInputElement).files;
		if(files && files.length>0){
			this.selectedFile = files[0];
		}    
		this.onFileSubmit();
	}

	onFileSubmit() {
		/*if (this.selectedFile) {
			this.documentProcessing = true
			// Send the file using an HTTP service or other methods
			const formData = new FormData();
			formData.append('file', this.selectedFile);
			// ... send formData using your preferred HTTP service
			this._mainService.digestExamsDocument(this.selectedFile).subscribe((digestedExams) => {
				//console.log('digestedExams', digestedExams)
				this.admission.name = digestedExams['name']
				this.admission.cf = digestedExams['cf']
				this.admission.degree_type = digestedExams['degree_type']
				this.admission.exam_vote = digestedExams['degree_vote']
				this.admission.languageLevel = digestedExams['english_exam_code']				
				this.admission.exams = []
				if(digestedExams['exams']){
					for(var examDigestedIndex in digestedExams['exams'] ){
						const examDigested = digestedExams['exams'][examDigestedIndex]
						const exam = new Exam();
						exam.cfu = Number(examDigested['cfu']);
						exam.description = examDigested['desc'];
						exam.ssd = examDigested['cod'];
						this.admission.exams.push(exam)
					}
				}
				this.documentProcessing = false
			})
		}*/
	}

	save(){
		this.savingMessage = 'Saving...' 
		this._mainService.saveAdmission(this.admission).subscribe((admission) => {
			//console.log('saveAdmission admission', admission)
			if(admission){
				this.savingMessage = 'Done!' 
				this.admission = admission
				setTimeout(() => {
					this.savingMessage = null 
				}, 1000);
			}
		})
	}
}
