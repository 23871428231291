<div class="container pb-2 mt-4">
	<h2>
		<i class="bi bi-chat-right-text-fill"></i> Admissions
	</h2>
	<div class="card mt-4">
		<div class="card-body">	
			<table class="table table-striped">
				<thead>
					<tr>
						<th>Id</th>
						<th>Nome e Cognome</th>
						<th>Corso</th>
						<th>Esito</th>
						<th>Data Valutazione</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let admission of admissions">
						<td>{{ admission.short_id ? admission.short_id : admission.id }}</td>
						<td>{{ admission.name }}</td>
						<td>{{ admission.course }}</td>
						<td>{{ admission.outcome }}</td>
						<td>{{ admission.inserted_at }}</td>
						<td>
							<button class="btn btn-flat-dark  ms-auto" [routerLink]="'/admission/' + admission.id">
								<i class="bi-binoculars"></i> Visualizza
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
