import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';
import { Router } from '@angular/router';
import { Admission } from '../../model/Admission';
import { KeyValue } from '../../model/KeyValue';

@Component({
	selector: 'app-admissions',
	templateUrl: './admissions.component.html',
	styleUrl: './admissions.component.scss',
})
export class AdmissionsComponent implements OnInit {
	admissions: Array<Admission> = [];
	ssds: Array<KeyValue> = [];
	degreeTypes: Array<KeyValue> = [];
	languageLevels: Array<KeyValue> = [];
	degree_votes: Array<KeyValue> = [];
	course: Array<KeyValue> = [];

	
	constructor(private _mainService: MainService, private router: Router) {		
	}

	/**
	 * On init
	 */
	ngOnInit() {
		this.getSSD()	
	}

	getAdmissions(){
		this._mainService.getAdmissions().subscribe((admissions) => {
			if (admissions) {				
				for (let admission_index = 0; admission_index < admissions.length; admission_index++) {
					for (let course_index = 0; course_index < this.course.length; course_index++) {
						if(this.course[course_index].key == admissions[admission_index].course){
							admissions[admission_index].course = this.course[course_index].value;
							break
						}
					}
				}
				this.admissions = admissions;
			}
		})
	}

	getSSD() {
		this._mainService.getSSDWithDetails()
			.subscribe(
				{
					next: (result) => {
						if (result) {
							this.ssds = result.ssd;
							this.degreeTypes = result.degree;
							this.languageLevels = result.language;
							this.degree_votes = result.degree_vote;
							this.course = result.course
						}
					},
					error: (e) => {
						console.log(e);
					},
					complete: () => {
						console.info('complete')
						this.getAdmissions()	
					}
				}
			);
	}
}
