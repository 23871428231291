import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit{

  constructor(private router: Router){}

  ngOnInit(): void {
    const pastPath = localStorage.getItem("pastPath")
    if(pastPath){
      this.router.navigate([pastPath])
      localStorage.removeItem("pastPath")
    } else {
      this.router.navigate([''])
    }    
  }



}
