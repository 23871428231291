import { Exam } from "./Exam";

export class Admission {
    id!:string;
    short_id!:string;
    name!:string;
    course!:string;
    outcome!:string;
    inserted_at!:string;
    request: any
    response: any
}